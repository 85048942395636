import type { IAfterSaleSelect } from '@/types/IAfterSaleSelect'

export const useAfterSaleStore = defineStore('afterSale', () => {
  // skuId
  const skuId = ref('')

  // 售后类型
  const isShowAfterSaleType = ref(false)

  // 当前激活的 orderId
  const currentOrderId = ref('')

  // 原因列表
  const reasonList: Ref<IAfterSaleSelect[]> = ref([])

  // 獲取的數據类型 set
  function setSkuId(data: string) {
    skuId.value = data
  }

  // 售后类型 set
  function setAfterSaleType(bool: boolean) {
    isShowAfterSaleType.value = bool
  }

  // orderId set
  function setCurrentOrderId(id: string) {
    currentOrderId.value = id
  }

  // orderId set
  function setReasonList(list: []) {
    reasonList.value = list
  }

  return {
    skuId,
    isShowAfterSaleType,
    currentOrderId,
    reasonList,
    setSkuId,
    setAfterSaleType,
    setCurrentOrderId,
    setReasonList,
  }
})
